import React, { Component } from 'react';
import '../css/App.css';

import 'whatwg-fetch';

// import { browserHistory } from 'react-router';
import  { Redirect, withRouter } from 'react-router-dom';
// import { Chart } from 'react-google-charts';
// import { withStyles } from '@material-ui/core/styles';
// import theme from '../components/Theme';
import {request, formatCurrency, gFormatCurrency, gFormatNumber, formatNumber, dateFromObjectId, languageCheckAndReload} from '../util';

import Header from '../components/Header';
import ConsumptionList from "../components/ConsumptionList";
import DatePicker from "../components/DatePicker";
import config from "../config";

import { translate } from "../i18n/customI18nProvider";
import Account from "../components/Account";
import ConsumptionsLineChart from "../components/ConsumptionsLineChart";
import VouchersTable from '../components/VouchersTable';
// import ActiveUsersLineChart from "../components/ActiveUsersLineChart";
import PeopleInEventLineChart from "../components/PeopleInEventLineChart";
// import SmartphonesInEventLineChart from "../components/SmartphonesInEventLineChart";
import SmartphonesInEventTable from "../components/SmartphonesInEventTable";
import ConsumptionsColumnChart from "../components/ConsumptionsColumnChart";
import AmountPieChart from "../components/AmountPieChart";
import ChargementsLineChart from "../components/ChargementsLineChart";
import EntranceCount from "../components/EntranceCount";
import DetailedConsumptions from "../components/statistics/DetailedConsumptions";
import { Grid } from '@material-ui/core';
import DetailedTransactionsExceptConsumptions from '../components/statistics/DetailedTransactionsExceptConsumptions';
import { getName, getRight, getToken, setName } from '../services/storage';

function compareSecondColumn(a, b) {
    if (a[1].v === b[1].v) {
        return 0;
    }
    else {
        return (a[1].v > b[1].v) ? -1 : 1;
    }
}

export default class Statistics extends Component {

    constructor(){
        super();
        this.state = {
            token: getToken(),
            right: getRight(),
            loading: true,
            statistics: {
                global: {},
                consumptions: [],
                entrances: [],
                history_debit: [],
                history_credit: [],
                history_people: [],
                history_people_in_event: []
            },
            mobileOpen: false,
            bar: {_id: ''},
            bars: [],
            test: [],
            domain_error: false,
            general_information: {
                name: getName() ? getName() : "Web App",
                dateBegin: null,
                dateEnd: null
            },
            dateBegin: '',
            dateEnd: ''
        };

        this.canReload = false;

    }

    async updateStatistics(pathname){

        if(!this.state.token)
            return;

        const pname = pathname ? pathname.split("/") : null;

        let new_state = this.state;

        new_state["loading"] = true;
        this.setState({'loading' : true});

        let dateBegin = null;
        let dateEnd = null;
        let bar_id = null;
        
        if(pname.length === 3) {
            bar_id = pname[2];
            dateBegin = null;
            dateEnd = null;
        } else if(pname.length === 4) {
            bar_id = '';
            dateBegin = pname[2];
            dateEnd = pname[3];
        } else if(pname.length === 5) {
            bar_id = pname[2];
            dateBegin = pname[3];
            dateEnd = pname[4];
        }

        new_state.bar = {_id: bar_id};
        await this.setState({'bar' : {_id: bar_id}});

        if(this.state.bars.length === 0 && this.state.right >= config.permissions.ADMIN.value){
            let bars = await request('/bars', 'GET', null)
            if(bars.status == 502) {
                await this.setState({loading: false, domain_error: true});
                return;
            } else {
                bars = bars.map(
                    bar => ({name: bar.name, link: '/statistics/' + bar._id, _id: bar._id})
                );
    
                new_state["bars"] = bars;
                // console.log("Bars", new_state["bars"]);
                // this.setState({'bars' : new_state["bars"]});
                await this.setState({'bars' : bars});
                // this.bars();

            }
        }

        let bars = this.state.bars;
        for (let i = 0; i < bars.length; i++) {
            if (bars[i]._id === bar_id) {
                await this.setState({'bar' : bars[i]});
                break;
            }
        }

        if(bar_id !== this.state.bar._id) {
            let bar = [];
            if(bar_id) {
                bar = await request('/bars/' + bar_id, 'GET', null);
                if (bar.error)
                    bar = {name: "Error"};
            }
            new_state["bar"] = bar;
            this.setState({'bar': bar});
        }

        await this.statistics(bar_id, dateBegin, dateEnd);
    }

    async componentDidMount(){

        let general_information = await request('/events/general', 'GET', null);
        if(general_information.error) {
            alert(general_information.error);
            return;
        }
        languageCheckAndReload(general_information.language);
        setName(general_information?.name || '');
        this.setState({general_information: general_information});
        
        this.unlisten = this.props.history.listen((location, action) => {
            // window.location.href = window.location.href;
            // this.setState({loading: true});
            this.updateStatistics(location.pathname);
            // this.setState({})
        });

        this.updateStatistics(window.location.pathname);
    }

    componentWillUnmount() {
        try {
            this.unlisten();
        } catch(e) {}
    }

    format_history = (history_stats, currency = true) => {
        const gFormat = currency ? gFormatCurrency : gFormatNumber;
        if(!history_stats)
            return;
        for(let i = 1; i < history_stats.length; i++){
            history_stats[i][0] = new Date(history_stats[i][0]);
            for(let j = 1; j < history_stats[i].length; j ++)
                history_stats[i][j] = gFormat(history_stats[i][j]);
        }
    };

    get_bars = () => {
        let credits = [];
        let checkout = [];
        let debits = [];

        if(this.state.statistics && this.state.statistics.bars) {
            let bars = this.state.statistics.bars;
            for (let bar in bars) {
                if(bars[bar].credit){
                    credits.push([bars[bar].name, gFormatCurrency(bars[bar].credit), formatNumber(bars[bar].credit)]);
                }
                if(bars[bar].checkout){
                    checkout.push([bars[bar].name, gFormatCurrency(bars[bar].checkout), formatNumber(bars[bar].checkout)]);
                }
                if(bars[bar].debit && bars[bar].debit > 0){
                    debits.push([bars[bar].name, gFormatCurrency(bars[bar].debit), formatNumber(bars[bar].debit)]);
                }
            }
        }

        credits.sort(compareSecondColumn);
        checkout.sort(compareSecondColumn);
        debits.sort(compareSecondColumn);

        credits.unshift(['Chargements', translate('custom.amount'), { role: 'annotation' }]);
        checkout.unshift([translate('custom.refunds'), translate('custom.amount'), { role: 'annotation' }]);
        debits.unshift([translate('custom.debits'), translate('custom.amount'), { role: 'annotation' }]);

        this.setState({
            credits,
            checkout,
            debits
        });
    };

    statistics = async(bar_id, dateBegin, dateEnd) => {

        bar_id = bar_id ? '/' + bar_id : '';
        
        let urlParams = '';
        if(dateBegin && dateEnd)
            urlParams = '/' + dateBegin + '/' + dateEnd;
        let statistics = await request('/statistics' + bar_id + urlParams, 'GET', null);
        this.setState({statistics});
        this.format_history(this.state.statistics.history_debit);
        this.format_history(this.state.statistics.history_credit);
        this.format_history(this.state.statistics.history_people, false);
        this.format_history(this.state.statistics.history_people_in_event, false);
        this.format_history(this.state.statistics.history_smartphones_in_event, false);
        this.get_bars();
        if(statistics.dateBegin)
            dateBegin = statistics.dateBegin;
        if(statistics.dateEnd)
            dateEnd = statistics.dateEnd;
        // let general_information = statistics.general_information;
        if(dateBegin && dateEnd){
            let dateBeginDate = dateFromObjectId(dateBegin);
            let dateEndDate = dateFromObjectId(dateEnd);
            // general_information.dateBegin = dateFromObjectId(dateBegin);
            // general_information.dateEnd = dateFromObjectId(dateEnd);
            this.setState({
                dateBegin: dateBeginDate,
                dateEnd: dateEndDate
            });
        }
        this.setState({loading: false});
        this.canReload = true;
    };

    render() {

        if(!this.state.token)
            return (<Redirect to='/login'  />);
        else
            return this.statistics_render();
    }

    handleRefreshClick = () => {
        if(this.canReload)
            this.updateStatistics(window.location.pathname);
    }

    statistics_render(){
        document.title = getName() ? getName() : config.applicationName;
        let statistics = (
            <div>
                <DatePicker
                    baseUrl='/statistics'
                    barId={this.state.bar._id}
                    dateBegin={this.state.dateBegin}
                    dateEnd={this.state.dateEnd}
                    minDateBegin={this.state.general_information.dateBegin}
                    maxDateEnd={this.state.general_information.dateEnd}
                    dayStartTime={this.state.general_information.dayStartTime}
                />

                {/* Movements Table */}
                <Account statistics={this.state.statistics.global} dateBegin={this.state.dateBegin} dateEnd={this.state.dateEnd} />
                
                {/* <div class="pagebreak"> </div> */}

                {/* Entrance Count Table */}
                <EntranceCount stats_clients={this.state.statistics.stats_clients} entrances={this.state.statistics.entrances} />
                

                {/*Amount Pie Chart*/}
                {/* <AmountPieChart
                    statistics={this.state.statistics}
                    currencyCode={this.state.general_information?.currencyCode}
                    currencySymbol={this.state.general_information?.currencySymbol}
                /> */}
                
                {/*Chargements Line Chart*/}
                <ChargementsLineChart
                    statistics={this.state.statistics}
                    currencyCode={this.state.general_information?.currencyCode}
                    currencySymbol={this.state.general_information?.currencySymbol}
                />
                
                {/*Consumptions Line Chart*/}
                <ConsumptionsLineChart
                    statistics={this.state.statistics}
                    currencyCode={this.state.general_information?.currencyCode}
                    currencySymbol={this.state.general_information?.currencySymbol}
                />
                
                {/*Active Users Line Chart*/}
                {/* <ActiveUsersLineChart statistics={this.state.statistics} /> */}
                
                {/*People In Event Line Chart*/}
                <PeopleInEventLineChart statistics={this.state.statistics} />
                
                {/*Smartphones In Event Line Chart*/}
                {/* <SmartphonesInEventLineChart statistics={this.state.statistics} /> */}

                {/* All Transactions View */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <DetailedTransactionsExceptConsumptions
                            barId={this.state.bar._id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DetailedConsumptions
                            barId={this.state.bar._id}
                        />
                    </Grid>
                </Grid>

                {/* Smartphones In Event Table */}
                <SmartphonesInEventTable statistics={this.state.statistics} />

                {/*Consumptions Column Chart*/}
                <ConsumptionsColumnChart
                    debits={this.state.debits}
                    currencyCode={this.state.general_information?.currencyCode}
                    currencySymbol={this.state.general_information?.currencySymbol}
                />
            
                {/* Vouchers Table */}
                <VouchersTable vouchers={this.state.statistics.vouchers} />
                
                {/*Consumption List*/}
                <ConsumptionList
                    statistics={this.state.statistics.consumptions}
                    general_information={this.state.general_information}
                    currencyCode={this.state.general_information?.currencyCode}
                    currencySymbol={this.state.general_information?.currencySymbol}
                />
            </div>
        );

        return (
                <Header
                    title={this.state?.bar?.name ? translate('custom.statistics') + ' \'' + this.state.bar.name + '\'' : translate('custom.statistics')}
                    name={this.state?.general_information?.name}
                    items={this.state.bars}
                    loading={this.state.loading}
                    home='/statistics'
                    refreshClicked = {this.handleRefreshClick}
                >
                    {(!this.state.loading && this.state.statistics && !this.state.domain_error) ? statistics : null}
                    {(!this.state.loading && this.state.domain_error) ? <h2 style={{textAlign: 'center', height: '100%'}}>{translate('custom.wrong_domain')}</h2> : null}
                </Header>
        );
    }
}

// const styles = theme => ({
// });

const styles = theme => ({
});


// export default withRouter(withStyles(styles)(Statistics));
// export default withStyles(styles)(Statistics);
// export default withRouter(Statistics);
